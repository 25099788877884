@use '@angular/material' as mat;
// @include mat-core();

$ao-red: (
  50: #fae8e1,
  100: #f4c5b4,
  200: #ec9e82,
  300: #e4774f,
  400: #df5a2a,
  500: #d93d04,
  600: #d53703,
  700: #cf2f03,
  800: #ca2702,
  900: #8c4126,
  A100: #ffebe9,
  A200: #ffbbb6,
  A400: #ff8c83,
  A700: #ff7569,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// The warn palette is optional (defaults to red).
$ao-warn: mat.define-palette(mat.$red-palette);

.ao-red {
  $ao-red-primary: mat.define-palette($ao-red);
  $ao-red-accent: mat.define-palette($ao-red, 900);
  $ao-red-warn: mat.define-palette($ao-warn);
  $ao-red-theme: mat.define-light-theme($ao-red-primary, $ao-red-accent, $ao-red-warn);

  @include theme-color-grabber($ao-red-theme);
  @include mat.all-legacy-component-themes($ao-red-theme);
  mat-label {
    color: var(--light-black);
  }
}
