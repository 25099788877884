@use '@angular/material' as mat;
$ao-grey: (
  50: #f4f5f5,
  100: #e3e5e7,
  200: #d1d4d7,
  300: #bfc2c7,
  400: #b1b5bb,
  500: #a3a8af,
  600: #9ba0a8,
  700: #91979f,
  800: #888d96,
  900: #505153,
  A100: #ffffff,
  A200: #dae7fc,
  A400: #a5c7ff,
  A700: #8bb7ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// The warn palette is optional (defaults to red).
$ao-warn: mat.define-palette(mat.$red-palette);

.ao-grey {
  $ao-grey-primary: mat.define-palette($ao-grey);
  $ao-grey-accent: mat.define-palette($ao-grey, 900);
  $ao-grey-warn: mat.define-palette($ao-warn);
  $ao-grey-theme: mat.define-light-theme($ao-grey-primary, $ao-grey-accent, $ao-grey-warn);

  @include theme-color-grabber($ao-grey-theme);
  @include mat.all-legacy-component-themes($ao-grey-theme);

  h1 {
    color: mat.get-color-from-palette($ao-grey-primary);
    text-align: center;
    padding: 20px 0px;
    margin: 0px;
    font-size: 0.75em;
    font-weight: normal;
  }

  h2 {
    color: mat.get-color-from-palette($ao-grey-primary);
    font-weight: normal;
  }
}
