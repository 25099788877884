@use '@angular/material' as mat;
$ao-orange: (
  50: #fff5ee,
  100: #ffe5d5,
  200: #ffd4b9,
  300: #ffc29c,
  400: #ffb587,
  500: #ffa872,
  600: #ffa06a,
  700: #ff975f,
  800: #ff8d55,
  900: #ff7d42,
  A100: #ffffff,
  A200: #ffffff,
  A400: #fff0ea,
  A700: #ffded0,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// The warn palette is optional (defaults to red).
$ao-warn: mat.define-palette(mat.$red-palette);

.ao-orange {
  $ao-orange-primary: mat.define-palette($ao-orange);
  $ao-orange-accent: mat.define-palette($ao-orange, 900);
  $ao-orange-warn: mat.define-palette($ao-warn);
  $ao-orange-theme: mat.define-light-theme($ao-orange-primary, $ao-orange-accent, $ao-orange-warn);

  @include theme-color-grabber($ao-orange-theme);
  @include mat.all-legacy-component-themes($ao-orange-theme);

  h1 {
    color: mat.get-color-from-palette($ao-orange-primary);
    text-align: center;
    padding: 20px 0px;
    margin: 0px;
    font-size: 0.75em;
    font-weight: normal;
  }

  h2 {
    color: mat.get-color-from-palette($ao-orange-primary);
    font-weight: normal;
  }
}
