@use '@angular/material' as mat;
$ao-green: (
  50: #e0e7e4,
  100: #b3c4ba,
  200: #809d8d,
  300: #4d765f,
  400: #26583c,
  500: #003b1a,
  600: #003517,
  700: #002d13,
  800: #00260f,
  900: #001908,
  A100: #58ff71,
  A200: #25ff46,
  A400: #00f125,
  A700: #00d721,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// The warn palette is optional (defaults to red).
$ao-warn: mat.define-palette(mat.$red-palette);

.ao-green {
  $ao-green-primary: mat.define-palette($ao-green);
  $ao-green-accent: mat.define-palette($ao-green, 900);
  $ao-green-warn: mat.define-palette($ao-warn);
  $ao-green-theme: mat.define-light-theme($ao-green-primary, $ao-green-accent, $ao-green-warn);

  @include theme-color-grabber($ao-green-theme);
  @include mat.all-legacy-component-themes($ao-green-theme);

  h1 {
    color: mat.get-color-from-palette($ao-green-primary);
    text-align: center;
    padding: 20px 0px;
    margin: 0px;
    font-size: 0.75em;
    font-weight: normal;
  }

  h2 {
    color: mat.get-color-from-palette($ao-green-primary);
    font-weight: normal;
  }
}
