// PLANTING MANAGEMENT
.planting-management-button
  border-radius: 0 !important
  border: none
  font-weight: bold
  font-size: 16px
  background-color: var(--green-active-link) !important
  color: var(--white) !important
  &:disabled
    background-color: var(--grey-light-icon) !important
