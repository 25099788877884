@import '@angular/material/theming'

=theme-color-grabber($theme)
  $primary: map-get($theme, primary)
  $accent: map-get($theme, accent)
  $warn: map-get($theme, warn)
  .primary
    color: mat-color($primary) !important
  .primary-lightest
    color: mat-color($primary, 100) !important
  .primary-dark
    color: mat-color($primary, 900) !important
  .primary-background
    background-color: mat-color($primary) !important
  .primary-lightest-background
    background-color: mat-color($primary, 100) !important
  .primary-light-background
    background-color: mat-color($primary, 200) !important
  .primary-dark-background
    background-color: mat-color($primary, 900) !important
  .accent-background
    background-color: mat-color($accent) !important
  .warn-background
    background-color: mat-color($warn) !important
  .primary-fill
    fill: mat-color($primary) !important
  .primary-dark-fill
    fill: mat-color($primary, 900) !important
  .accent-fill
    fill: mat-color($accent) !important
  .warn-fill
    fill: mat-color($warn) !important
