@use '@angular/material' as mat;
$ao-blue: (
  50: #f0f8f7,
  100: #d9eeeb,
  200: #bfe3de,
  300: #a5d8d1,
  400: #92cfc7,
  500: #7fc7bd,
  600: #77c1b7,
  700: #6cbaae,
  800: #62b3a6,
  900: #196674,
  A100: #ffffff,
  A200: #d3fff8,
  A400: #a0ffef,
  A700: #86ffeb,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// The warn palette is optional (defaults to red).
$ao-warn: mat.define-palette(mat.$red-palette);

.ao-blue {
  $ao-blue-primary: mat.define-palette($ao-blue);
  $ao-blue-accent: mat.define-palette($ao-blue, 900);
  $ao-blue-warn: mat.define-palette($ao-warn);
  $ao-blue-theme: mat.define-light-theme($ao-blue-primary, $ao-blue-accent, $ao-blue-warn);

  @include theme-color-grabber($ao-blue-theme);
  @include mat.all-legacy-component-themes($ao-blue-theme);

  h1 {
    color: mat.get-color-from-palette($ao-blue-primary);
    text-align: center;
    padding: 20px 0px;
    margin: 0px;
    font-size: 0.75em;
    font-weight: normal;
  }

  h2 {
    color: mat.get-color-from-palette($ao-blue-primary);
    font-weight: normal;
  }
}
