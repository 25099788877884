.spin
  animation-name: spin
  animation-duration: 3000ms
  animation-iteration-count: infinite
  animation-timing-function: linear

@keyframes spin
  from
    transform: rotate(360deg)
  to
    transform: rotate(0deg)
