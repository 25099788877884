@use '@angular/material' as mat;
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
@import 'style/mixins';

@import 'style/themes/green-theme';
@import 'style/themes/light-green-theme';
@import 'style/themes/yellow-theme';
@import 'style/themes/red-theme';
@import 'style/themes/blue-theme';
@import 'style/themes/orange-theme';
@import 'style/themes/grey-theme';
@import 'style/fonts.scss';
@import 'style/buttons.sass';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 20px;
}

// MAT BUTTON
.mat-raised-button {
  margin: auto !important;
  margin-top: 25px !important;
  margin-bottom: 30px !important;
  color: var(--white) !important;
  height: 38px !important;
  min-width: 180.3px !important;
  padding: 13px !important;
  border-radius: 12px !important;
  text-align: center !important;
  line-height: 0.94 !important;
  display: block !important;
  font-size: 0.9em;
}
// MAT FORM FIELD
.mat-form-field {
  width: 100%;
}
.mat-form-field-underline {
  display: none;
}
.mat-form-field-infix {
  padding: 0 !important;
  margin: 0px;
  border-top-width: 0px;
}
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0px;
}
// MAT ERROR
.mat-error {
  font-size: 0.75em;
}
// MAT INPUT
input.mat-input-element {
  border: 1px solid var(--grey);
  margin-top: -0.0625em;
  padding-left: 0.5em;
  width: calc(100% - 0.5em);
  background: var(--white);
  height: 40px;
}
.mat-select {
  border: 1px solid var(--grey);
  margin-top: -0.0625em;
  background: var(--white);
  height: 38px;
  padding: 1px 0 1px 0;
}
.mat-select-trigger {
  vertical-align: -webkit-baseline-middle;
  padding-right: 0.5em;
}
.mat-select-value-text,
.mat-option-text {
  margin-left: 0.5em;
  font-size: 20px;
}
.mat-select .mat-select-arrow {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid;
  color: var(--grey-light-icon);
}

// MAT LABEL
mat-label {
  padding: 5px 0px;
  display: flex;
  font-size: 0.75em;
}
// MAT ICON
.mat-icon.active {
  background: var(--grey-light);
  border-radius: 10px;
  padding: 10px 15px;
  margin: 0px 5px;
}
.mat-icon.active.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: 7px !important;
  bottom: 5px !important;
}
